import React from 'react'
import { NewsFragment } from './__generated__/news'
import { CardDesignItem } from '../../../components/CardDesignCarousel/CardDesignItem'
import { CardDesignCarousel } from '../../../components/CardDesignCarousel/CardDesignCarousel'
import { StyledHeading } from '../styledHeading'
import { nonNull } from '../../../utils/nonNull'
import { DEFAULT_NEWS_IMAGE } from '../../../constants/defaultImages'

export type NewsProps = {
  data: NewsFragment[]
}

export const News: React.FC<NewsProps> = ({ data }) => {
  return (
    <>
      <StyledHeading variant="h5" align="center">
        Latest News Articles
      </StyledHeading>
      <CardDesignCarousel>
        {nonNull(data).map((item, index) => {
          if( item.__typename === 'news_newsEntryType_Entry') {
            const url = item.newsDetailBanner?.[0]?.bannerImage?.[0]?.url

            return (
              <CardDesignItem key={index} href={item.uri || '#'}>
                <CardDesignItem image src={url ? url : DEFAULT_NEWS_IMAGE} alt={''} layout="fill" objectFit="cover" objectPosition="center" />
                <CardDesignItem date>{new Date(item.postDate).toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' })}</CardDesignItem>
                <CardDesignItem title>{item.title}</CardDesignItem>
              </CardDesignItem>
            ) 
          }

          return null
        })}
      </CardDesignCarousel>
    </>
  )
}